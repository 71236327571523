import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Troubleshooting",
  "path": "/troubleshooting"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Troubleshooting`}</h1>
    <p>{`Learn how to resolve the most common problems people encounter in the REST API.`}</p>
    <p>{`If you're encountering some oddities in the API,
here's a list of resolutions to some of the problems you may be experiencing.`}</p>
    <h4>{`401 error`}</h4>
    <p>{`The user or client authentication failed. This might be due to token
having expired or the user not having sufficient scopes and
permissions. (check??)`}</p>
    <p>{`Use jwt.io and paste your token there to verify that you have a valid
token and appropriates scopes listed in the token`}</p>
    <h4>{`404 error`}</h4>
    <p>{`This error is returned when an accountId or employeeId or candidateId
is not found. Use the enablehr website to verify that the id's used
in your call are valid for your user.`}</p>
    <h4>{`403 error`}</h4>
    <p>{`The user does not have permissions to access the resource being
requested. Use the enablehr website to verify that the user has the
appropriate roles (which in turn have permissions associated with
them) in enablehr.`}</p>
    <h4>{`500 error`}</h4>
    <p>{`Internal Server Error. Try again a few times and if not resolved,
possibly a severe fault and needs to be reported to enableHR support.
It is likely that the server is unable to handle your request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      